






































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import VsPercentageBar from '@/modules/mediaLibrary/components/VsPercentageBar/Index.vue'
import { UserModule } from '@/store/modules/user'
import VsInputPassword from '@/components/VsInputPassword/Index.vue'
import { createChild, getOverviewChilds } from '@/api/userapi/manager/users'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'

@Component({
    name: 'VsCreateChildModal',
    components: {
        VsActionCard,
        VsPercentageBar,
        VsInputPassword,
    },
})
export default class extends Vue {
    open = false
    loading = false

    get userType () {
        if (this.childData.configurationSlug === 'starter') return 'Starter'
        if (this.childData.configurationSlug === 'expert') return 'Expert'
        return ''
    }

    private childData = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        password: '',
        configurationSlug: '',
    }

    overviewChildrenData: any = null

    $refs!: any

    get user () {
        return UserModule.user
    }

    get specialChar () {
        return /^[A-Z0-9@&'".,\-àèìòù\s]*$/i
    }

    openModal () {
        this.childData = {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            company: '',
            password: '',
            configurationSlug: '',
        }
        this.getOverviewChildren()
        this.open = true
    }

    closeModal () {
        this.open = false
    }

    async getOverviewChildren () {
        try {
            const resp = await getOverviewChilds()
            this.overviewChildrenData = resp.data
        } catch (e) {
            this.overviewChildrenData = null
        }
    }

    async createChild () {
        const valid = await this.$refs.createChildForm.validate()
        if (!valid) return
        this.loading = true
        try {
            await createChild({ ...this.childData, phone: this.childData.phone || null })
            this.$root.$vsToast({
                heading: 'Account creato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('user-created')
            this.closeModal()
        } catch (e) {
            console.log(e)
            const errorCode = get(e, 'response.data.message', '')
            let message = ''
            if (errorCode === 'UserAlreadyExists') {
                this.$refs.createChildForm.setErrors({ childEmail: ['Indirizzo email già esistente oppure non valido'] })
            }
            if (errorCode === 'CannotCreateChild') {
                message = 'Non puoi creare utenti di questa tipologia'
            }
            this.$root.$vsToast({
                heading: 'Errore durante la creazione dell\'account',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }
}
