
















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { copyToClipboard } from '@/utils/copyToClipboard'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { userStatusEnum } from '@/utils/users'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { getManagerChildCredits } from '@/api/consoleApi/manager'
import { IUser } from '@/store/modules/user'

@Component({
    name: 'VsChildCard',
    components: {
        VsListingCard,
        VsDropdownButton,
        VsConfirm,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) user!: IUser
    loading = false

    credits = {
        email: 0,
        sms: 0,
        transactional: 0,
    }

    $refs: any

    get badgeStatusAspect () {
        switch (this.user.status) {
            case userStatusEnum.ENABLED: return 'success'
            case userStatusEnum.DISABLED: return 'alert'
            case userStatusEnum.PENDING_ACTIVATION: return 'warning'
            case userStatusEnum.PENDING_ENABLED:
            default: return 'subtle'
        }
    }

    get emailNotUnderZero () {
        return this.credits.email < 0 ? 0 : this.credits.email
    }

    get parentType () {
        if (!this.user.parent?.relationType) return 'Indipendent'
        if (this.user.parent.relationType === 'starter') return 'Starter'
        if (this.user.parent.relationType === 'expert') return 'Expert'
        return 'Indipendent'
    }

    beforeMount () {
        if (this.user.parent?.relationType) {
            this.getChildCredits()
        }
    }

    private copyToClipboard (stringToCopy: any) {
        try {
            copyToClipboard(stringToCopy)
            this.$root.$vsToast({
                heading: this.$t('general.copyClip'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('general.copyClipError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private async changeUserStatus (event: string) {
        try {
            await this.$refs.confirmUserStatus.openConfirm()
            this.$emit(event)
        } catch (e) {
        }
    }

    async getChildCredits () {
        try {
            const resp = await getManagerChildCredits(this.user._id)
            this.credits = resp.data.data
        } catch (e) {
            console.log(e)
        }
    }
}
