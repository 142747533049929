



































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsFilterContainer from '@/components/VsFilterContainer/Index.vue'
import VsChildCard from '@/modules/manager/components/VsChildCard/Index.vue'
import VsCreateChildModal from '@/modules/manager/components/VsCreateChildModal/Index.vue'
import { changeChildStatus, deleteChild, getChilds, impersonateChild, switchChildToExpert } from '@/api/userapi/manager/users'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
import { userStatusEnum } from '@/utils/users'
import { UserModule } from '@/store/modules/user'
import { canIncludesInArray } from '@/utils/rules'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'UsersIndex',
    components: {
        VsContainer,
        VsLoader,
        VsSectionHeader,
        VsCreateChildModal,
        VsChildCard,
        VsFilterContainer,
        VsConfirm,
    },
})
export default class extends Vue {
    loading = false
    users: any[] = []
    total = 0
    pagination = {
        page: 1,
        itemsPerPage: 5,
        sortBy: null,
    }

    private filters: any = {
        status: null,
        relationType: null,
        search: null,
    }

    $refs!: any

    get user () {
        return UserModule.user
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get isMdViewport () {
        return AppModule.isMdViewport
    }

    get canCreateUser () {
        return canIncludesInArray(this.user.configuration.rules.managerRules.webAppPermissions, 'users.create')
    }

    get userStatusOptions () {
        return [
            {
                value: userStatusEnum.ENABLED,
                label: this.$t(`manager.userStatus.${userStatusEnum.ENABLED}`),
            },
            {
                value: userStatusEnum.DISABLED,
                label: this.$t(`manager.userStatus.${userStatusEnum.DISABLED}`),
            },
        ]
    }

    get relationTypeOptions () {
        return [
            {
                value: 'starter',
                label: 'Starter',
            },
            {
                value: 'expert',
                label: 'Expert',
            },
            // {
            //     value: 'indipendent',
            //     label: 'Indipendent',
            // },
        ]
    }

    get countFilters () {
        let count = 0
        if (this.filters.relationType) count++
        if (this.filters.status) count++
        if (this.filters.search) count++
        return count
    }

    get orderByOptions () {
        return [
            {
                value: '-|createdAt',
                label: 'Più recenti',
            },
            {
                value: '+|createdAt',
                label: 'Meno recenti',
            },
            {
                value: '-|updatedAt',
                label: 'Ultime modifiche',
            },
            {
                value: '-|lastLogin',
                label: 'Ultimo accesso',
            },
        ]
    }

    @Watch('filters', { immediate: false, deep: true })
    async setPageAndGetUsers () {
        if (this.pagination.page > 1) {
            this.pagination.page = 1
        } else {
            this.getUsers()
        }
    }

    @Watch('pagination', { immediate: true, deep: true })
    async getUsers () {
        this.loading = true
        try {
            const resp = await getChilds(this.generateQuery())
            this.users = resp.data.docs
            this.total = resp.data.count
        } catch (e) {
            console.log(e)
            this.total = 0
            this.users = []
        }
        this.loading = false
    }

    private generateQuery () {
        const sortBy = this.pagination.sortBy || '-|createdAt'
        const sortSplit = sortBy.split('|')
        const query: any = {
            limit: this.pagination.itemsPerPage,
            skip: (this.pagination.page - 1) * this.pagination.itemsPerPage,
            sort: `${sortSplit[0]}${sortSplit[1]}`,
        }

        const filter: any = {
            $and: [],
        }

        if (this.filters.search) {
            const checkForHexRegExp = /^[a-f\d]{24}$/i
            const $or: any[] = [
                { email: { $regex: this.filters.search, $options: 'i' } },
                { firstname: { $regex: this.filters.search, $options: 'i' } },
                { lastname: { $regex: this.filters.search, $options: 'i' } },
                { company: { $regex: this.filters.search, $options: 'i' } },
            ]
            if (checkForHexRegExp.test(this.filters.search)) {
                $or.push({ _id: this.filters.search })
            }
            filter.$and.push({
                $or,
            })
        }

        if (this.filters.status) {
            filter.$and.push({
                status: this.filters.status,
            })
        }

        if (this.filters.relationType) {
            filter.$and.push({
                'parent.relationType': this.filters.relationType === 'indipendent' ? '' : this.filters.relationType,
            })
        }

        if (filter.$and.length > 0) {
            query.filter = JSON.stringify(filter)
        }
        return query
    }

    async deleteUser (userId: any) {
        try {
            await this.$refs.vsConfirmDeleteUser.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await deleteChild(userId)
            this.$root.$vsToast({
                heading: 'Account eliminato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.getUsers()
        } catch (e) {
            console.log(e)
            let message = ''
            const error = get(e, 'response.data.message', '')
            if (error === 'UserIsNotDeletable') {
                message = 'Disabilita l\'utente prima di eliminarlo.'
            }
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione dell\'account',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            this.loading = false
        }
    }

    async impersonateUser (userId: string) {
        try {
            await impersonateChild(userId)
            window.location.href = '/user'
        } catch (e) {
            const code: string = get(e, 'response.data.message', '')
            let message = ''
            if (code === 'ChildNoPermission' || code === 'ManagerRuleNotAllowed') message = 'Non hai i permessi per accedere alla console dell\'account.'
            if (code === 'ChildNotEnabled') message = 'L\'account deve essere abilitato.'
            if (code === 'ManagerRuleNotAllowed') message = 'L\'account deve essere abilitato.'

            this.$root.$vsToast({
                heading: 'Impossibile accedere alla console',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    openCreateChild () {
        this.$refs.vsCreateChildModal.openModal()
    }

    async changeStatus (childId: string, status: string) {
        try {
            this.loading = true
            const data = {
                status,
                disabledReason: status === 'enabled' ? '' : 'DISABLED_BY_MANAGER',
                sendNotification: false,
            }
            await changeChildStatus(childId, data)
            this.$root.$vsToast({
                heading: 'Status cambiato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.getUsers()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il cambio di status',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            this.loading = false
        }
    }

    async upgradeToExpert (childId: string) {
        try {
            await this.$refs.vsUpgradeToExpertConfirm.openConfirm()
        } catch (e) {
            return
        }

        try {
            this.loading = true
            await switchChildToExpert(childId)

            this.$root.$vsToast({
                heading: 'Utente trasformate in Expert con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })

            this.getUsers()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il cambio di piano',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            this.loading = false
        }
    }
}
